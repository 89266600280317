import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Divider from "@mui/material/Divider";
import icon_engine from "assets/images/icon-engine.png";
import icon_gear from "assets/images/icon-gear.png";
import icon_delivery from "assets/images/icon-delivery.png";
import {
  Box,
  Modal,
  Grid,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormControl,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMediaQuery } from '@mui/material';

import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import BuildIcon from "@mui/icons-material/Build";
import PaletteIcon from "@mui/icons-material/Palette";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import MKBadge from "components/MKBadge";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import AddCircleIcon from "@mui/icons-material/AddCircle"; // Importa el icono si no está importado
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CenteredBlogCardEquip from "examples/Cards/BlogCards/CenteredBlogCardEquip";
import Pagination from "@mui/material/Pagination";
//import CustomCarousel from './Carousel';
import { useNavigate } from "react-router-dom";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = ({ className, style, onClick }) => (
  <ArrowForwardIos
    className={className}
    style={{
      ...style,
      display: "block",
      color: "#FFFFFF",
      fontSize: "3rem",
      fontWeight: "bold",
      width: "40px",
      height: "40px",
      position: "absolute",
      top: "-10%", // Posiciona debajo del slider
      left: "50%", // Centra horizontalmente
      transform: "translateX(-50%) rotate(-90deg)", // Centra y rota hacia abajo
    }}
    onClick={onClick}
  />
);

const PrevArrow = ({ className, style, onClick }) => (
  <ArrowBackIos
    className={className}
    style={{
      ...style,
      display: "block",
      color: "#FFFFFF",
      fontSize: "3rem",
      fontWeight: "bold",
      width: "40px",
      height: "40px",
      position: "absolute",
      top: "102%", // Posiciona encima del slider
      left: "50%", // Centra horizontalmente
      transform: "translateX(-50%) rotate(-90deg)", // Centra y rota hacia abajo
    }}
    onClick={onClick}
  />
);

const ConfigFilter = ({ carSetSon360, modalClose }) => {
  const [modelos, setModelos] = useState([]);
  const [versiones, setVersiones] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [colores, setColores] = useState([]);
  const [equipamientos, setEquipamientos] = useState([]);
  const [modeloSeleccionado, setModeloSeleccionado] = useState("");
  const [versionSeleccionada, setVersionSeleccionada] = useState("");
  const [colorSeleccionado, setColorSeleccionado] = useState("");
  const [equipamientoSeleccionado, setEquipamientoSeleccionado] = useState([]);
  const [motores, setMotores] = useState([]); // Ahora motores es un array
  const [medios, setMedios] = useState([]); // Ahora motores es un array
  const [mediosSeleccionado, setMediosSeleccionado] = useState([]); // Ahora motores es un array
  const [cambio, setCambio] = useState([]); // Ahora cambio es un array para múltiples selecciones
  const [cambioSeleccionado, setCambioSeleccionado] = useState([]); // Ahora cambio es un array para múltiples selecciones
  const [motorSeleccionado, setMotorSeleccionado] = useState([]); // Ahora cambio es un array para múltiples selecciones
  const [typeSeleccionado, setTypeSeleccionado] = useState('Turismo');

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [dragStartX, setDragStartX] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [equipSelect, setEquipSelect] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleButtonClick = () => {
    carSetSon360(
      typeSeleccionado,
      modeloSeleccionado,
      versionSeleccionada,
      colorSeleccionado,
      motorSeleccionado,
      cambioSeleccionado,
      mediosSeleccionado,
      equipamientoSeleccionado
    );
    modalClose();
    /*console.log("test01", [
      modeloSeleccionado,
      versionSeleccionada,
      colorSeleccionado,
      motorSeleccionado,
      cambioSeleccionado,
      mediosSeleccionado,
      equipamientoSeleccionado,
    ]);*/
  };

  useEffect(() => {
    obtenerMedios();
    obtenerCambios();
    obtenerMotores();
  }, [typeSeleccionado]);

  useEffect(() => {
    obtenerModelos(typeSeleccionado,motorSeleccionado, cambioSeleccionado, mediosSeleccionado);
  }, [typeSeleccionado,motorSeleccionado, cambioSeleccionado, mediosSeleccionado]);

  useEffect(() => {
    if (modeloSeleccionado) {
      obtenerVersiones(
        modeloSeleccionado,
        motorSeleccionado,
        cambioSeleccionado,
        mediosSeleccionado
      );
      if (versionSeleccionada) {
        obtenerColores(versionSeleccionada);
        if (colorSeleccionado) {
          obtenerEquipamientos(
            modeloSeleccionado,
            versionSeleccionada,
            colorSeleccionado
          );
        } else {
          setEquipamientos([]);
        }
      } else {
        setColores([]);
      }
      obtenerVehiculosPorModeloYVersion(
        modeloSeleccionado,
        versionSeleccionada,
        colorSeleccionado,
        equipamientoSeleccionado
      );
    } else {
      setVersiones([]);
    }
  }, [
    typeSeleccionado,
    modeloSeleccionado,
    versionSeleccionada,
    colorSeleccionado,
    motorSeleccionado,
    cambioSeleccionado,
    mediosSeleccionado,
    equipamientoSeleccionado,
  ]);

  const obtenerModelos = (
    typeSeleccionado,
    motorSeleccionado,
    cambioSeleccionado,
    mediosSeleccionado
  ) => {
    const motoresQuery =
      motorSeleccionado.length > 0
        ? encodeURIComponent(motorSeleccionado.join(","))
        : "";
    const cambioQuery =
      cambioSeleccionado.length > 0
        ? encodeURIComponent(cambioSeleccionado.join(","))
        : "";
    const mediosQuery =
      mediosSeleccionado.length > 0
        ? encodeURIComponent(mediosSeleccionado.join(","))
        : "";

    //console.log(`https://www.api.kodecarsmarket.com/api/modelos?motor=${motoresQuery}&cambio=${cambioQuery}&medio=${mediosQuery}`)

    axios
      .get(
        `https://www.api.kodecarsmarket.com/api/modelos?type=${typeSeleccionado}&motor=${motoresQuery}&cambio=${cambioQuery}&medio=${mediosQuery}`
      )
      .then((response) => {
        setModelos(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener modelos:", error);
      });
  };

  const obtenerVersiones = async (
    modeloId,
    motorSeleccionado,
    cambioSeleccionado,
    mediosSeleccionado
  ) => {
    const motoresQuery =
      motorSeleccionado.length > 0
        ? encodeURIComponent(motorSeleccionado.join(","))
        : "";
    const cambioQuery =
      cambioSeleccionado.length > 0
        ? encodeURIComponent(cambioSeleccionado.join(","))
        : "";
    const mediosQuery =
      mediosSeleccionado.length > 0
        ? encodeURIComponent(mediosSeleccionado.join(","))
        : "";

    //console.log(`https://www.api.kodecarsmarket.com/api/versiones?modelo_id=${modeloId}&motor=${motoresQuery}&cambio=${cambioQuery}&medio=${mediosQuery}`)
    try {
      const response = await fetch(
        `https://www.api.kodecarsmarket.com/api/versiones?modelo_id=${modeloId}&motor=${motoresQuery}&cambio=${cambioQuery}&medio=${mediosQuery}`
      );
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setVersiones(data || []);
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const obtenerColores = async (versionId) => {
    try {
      const response = await fetch(
        `https://www.api.kodecarsmarket.com/api/colores?version_id=${versionId}`
      );
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setColores(data.colores || []);
      console.log('colores', data.colores)
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const obtenerEquipamientos = async (modelo, version, color) => {
    try {
      const response = await fetch(
        `https://www.api.kodecarsmarket.com/api/equipamiento?modelo_id=${modelo}&version_id=${version}&color_id=${color}`
      );
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setEquipamientos(data.Equipamiento || []);
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const obtenerVehiculosPorModeloYVersion = async (
    modelo,
    version,
    color,
    equipx_id
  ) => {
    /*console.log(
      "url",
      `https://www.api.kodecarsmarket.com/api/productos?modelox_id=${modelo}&versionx_id=${version}&colorx_id=${color}&equipx_id=${equipx_id}`
    );*/
    try {
      const response = await fetch(
        `https://www.api.kodecarsmarket.com/api/productos?modelox_id=${modelo}&versionx_id=${version}&colorx_id=${color}&equipx_id=${equipx_id}`
      );
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setVehiculos(data.productos || []);
      //console.log('test', data.productos)
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const obtenerMedios = async () => {
    try {
      const response = await fetch(`https://www.api.kodecarsmarket.com/api/medios?type=${typeSeleccionado}`);
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setMedios(data.medios || []); // Asegúrate de que no se seleccione ningún medio por defecto
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const obtenerCambios = async () => {
    try {
      const response = await fetch(`https://www.api.kodecarsmarket.com/api/cambios?type=${typeSeleccionado}`);
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setCambio(data.Cambios || []); // Asegúrate de que no se seleccione ningún medio por defecto
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const obtenerMotores = async () => {
    try {
      const response = await fetch(`https://www.api.kodecarsmarket.com/api/motores?type=${typeSeleccionado}`);
      if (!response.ok) {
        throw new Error("No se pudieron cargar los detalles del producto");
      }
      const data = await response.json();
      setMotores(data.Motores || []); // Asegúrate de que no se seleccione ningún medio por defecto
    } catch (error) {
      console.error("Error al obtener los detalles del producto:", error);
    }
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;
    setTypeSeleccionado(value
    );
  };

  const handleMotoresChange = (e) => {
    const value = e.target.value;
    setMotorSeleccionado(
      (prevState) =>
        prevState.includes(value)
          ? prevState.filter((c) => c !== value) // Remover si ya está seleccionado
          : [...prevState, value] // Agregar si no está seleccionado
    );
  };

  const handleCambioChange = (e) => {
    const value = e.target.value;
    setCambioSeleccionado(
      (prevState) =>
        prevState.includes(value)
          ? prevState.filter((c) => c !== value) // Remover si ya está seleccionado
          : [...prevState, value] // Agregar si no está seleccionado
    );
  };

  const handleMediosImageClick = (medio) => {
    setMediosSeleccionado(
      (prevState) =>
        prevState.includes(medio.imagen)
          ? prevState.filter((c) => c !== medio.imagen) // Remover si ya está seleccionado
          : [...prevState, medio.imagen] // Agregar si no está seleccionado
    );
  };

  const handleMouseDown = (e) => {
    setDragStartX(e.clientX);
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (isDragging && dragStartX !== null) {
      const dragDistance = e.clientX - dragStartX;
      const sensitivity = 50;

      if (Math.abs(dragDistance) > sensitivity) {
        const imageMove = Math.sign(dragDistance);
        setCurrentImageIndex((prevIndex) => {
          let newIndex = prevIndex + imageMove;
          if (newIndex < 0) newIndex = carImages.length - 1;
          if (newIndex >= carImages.length) newIndex = 0;
          return newIndex;
        });
        setDragStartX(e.clientX);
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragStartX(null);
  };

  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false);
      setDragStartX(null);
    }
  };

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const carImages =
    vehiculos.length > 0
      ? Array.from({ length: 24 }, (_, i) =>
          require(`assets/images/vehiculos/${vehiculos[0].product_id}/${vehiculos[0].product_id}-${i}.jpeg`)
        )
      : [];

  useEffect(() => {
    carImages.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, [carImages]);

  const settings = {
    centerMode: true,
    centerPadding: "10px",
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    vertical: true, // Habilita el carrusel vertical
    verticalSwiping: true, // Habilita el swipe vertical
    slidesToShow: 3, // Número de elementos visibles en el carrusel vertical
    centerMode: true, // Deshabilita el modo centrado para vertical
    speed: 500,
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  // Filtrado del color seleccionado en el array de colores
  const colorActual = colores.find(
    (color) => color.id === parseInt(colorSeleccionado)
  );

  // Filtrado del color seleccionado en el array de colores
  const versionActual = versiones.find(
    (version) => version.id === parseInt(versionSeleccionada)
  );

  const equipamientoActual = equipamientos.find(
    (equip) => equip.id === parseInt(equipamientoSeleccionado)
  );
  //console.log('testo', equipamientoActual)

  // Si vehiculos[0]?.portada está en formato JSON string, primero necesitas parsearlo
  const portadaData = vehiculos[0]?.portada ? vehiculos[0]?.portada : null;
  // Extraer la entrega
  const entrega = portadaData
    ? portadaData[0]?.entrega
    : "Datos no disponibles";

  // Extraer la motor
  const motor = portadaData ? portadaData[0]?.motor : "No disp.";

  // Extraer la cambio
  const cambiop = portadaData ? portadaData[0]?.cambio : "No disp.";

  // Extraer la precio
  const precio = portadaData ? portadaData[0]?.precio : "Datos no disponibles";

  const cuota = portadaData ? portadaData[0]?.cuota : "Datos no disponibles";

  let ambient = null;

  // Verifica si vehiculos[0]?.caract_adicionales es válido
  if (vehiculos[0]?.caract_adicionales) {
    try {
      // Parseamos solo una vez
      const caractAdicionales = JSON.parse(vehiculos[0].caract_adicionales);
  
      // Intentamos acceder a 'CO2 ciclo mixto WLTP (g/km)' en los índices 3 y 2
      ambient = caractAdicionales?.[0]?.[3]?.['CONSUMOS Y EMISIONES']?.['CO2 ciclo mixto WLTP (g/km)'] ??
                caractAdicionales?.[0]?.[2]?.['CONSUMOS Y EMISIONES']?.['CO2 ciclo mixto WLTP (g/km)'] ?? 
                null;
    } catch (error) {
      console.error("Error al parsear caract_adicionales:", error);
    }
  }
  
  console.log("ambient:", ambient);
  
  const [price, setPrice] = useState(0);
  const [cuote, setCuote] = useState(0);

  useEffect(() => {
    const ambientValue = parseInt(ambient, 10);  
  
    if (ambientValue === 0) {
    } else if (ambientValue <= 110) {
      setPrice(0);
      setCuote(25.21);
    } else if (ambientValue <= 159) {
      setPrice(35.63);
      setCuote(26.20);
    } else if (ambientValue <= 199) {
      setPrice(73.13);
      setCuote(27.24);
    } else {
      setPrice(120);
      setCuote(28.54);
    }
  }, [ambient]); 


  const pages = [
    <>
      <Card
        sx={{
          marginBottom: "20px",
          minHeight: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.07)",
          padding: "20px",
          display: "flex", // Alinea los elementos en una fila
          flexWrap: "wrap", // Permite que los elementos se envuelvan si no hay suficiente espacio
          justifyContent: "space-between", // Distribuye los elementos equitativamente
          alignItems: "flex-start", // Alinea los formularios al inicio
          overflow: "auto", // Muestra la barra de desplazamiento si el contenido es largo
          //maxHeight: "300px", // Limita la altura de la tarjeta
        }}
      >
        <Grid container sx={{ width: "100%" }}>
        <Grid item lg={3}>
            <FormControl
              sx={{ m: 3, flex: "1 1 45%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel
                component="legend"
                sx={{
                  color: "rgba(255, 255, 255, 0.60)",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  "&.Mui-focused": {
                    color: "#FFD415", // Color amarillo cuando está enfocado
                  },
                  "&.Mui-error": {
                    color: "#FFD415", // Color amarillo cuando hay un error
                  },
                }}
              >
                Tipo de Vehículo
              </FormLabel>
              <FormGroup sx={{ marginTop: "10px" }}>
              <FormControlLabel
        control={
          <Checkbox
          checked={typeSeleccionado === 'Turismo'}
          onChange={handleTypeChange}
            value={'Turismo'}
            sx={{ color: 'rgba(255, 255, 255, 0.60)' }}
          />
        }
        label={'Turismo'}
        sx={{
          color: 'rgba(255, 255, 255, 0.60)',
          '& .MuiTypography-root': {
            color: 'rgba(255, 255, 255, 0.60)',  // Asegura que el texto de la etiqueta sea blanco
            fontWeight: 'regular',
          }
        }}  
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={typeSeleccionado === 'Comercial'}
            onChange={handleTypeChange}
            value={'Comercial'}
            sx={{ color: 'rgba(255, 255, 255, 0.60)' }}
          />
        }
        label={'Comercial'}
        sx={{
          color: 'rgba(255, 255, 255, 0.60)',
          '& .MuiTypography-root': {
            color: 'rgba(255, 255, 255, 0.60)',  // Asegura que el texto de la etiqueta sea blanco
            fontWeight: 'regular',
          }
        }}  
      />
              </FormGroup>
              <FormHelperText
                sx={{
                  color: "rgba(255, 255, 255, 0.60)",
                  fontSize: "0.8rem",
                  width: "80%",
                  marginTop: "10px",
                }}
              >
                Selecciona uno, mas de uno o ninguno
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item lg={3}>
            <FormControl
              sx={{ m: 3, flex: "1 1 45%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel
                component="legend"
                sx={{
                  color: "rgba(255, 255, 255, 0.60)",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  "&.Mui-focused": {
                    color: "#FFD415", // Color amarillo cuando está enfocado
                  },
                  "&.Mui-error": {
                    color: "#FFD415", // Color amarillo cuando hay un error
                  },
                }}
              >
                Motorizaciónes
              </FormLabel>
              <FormGroup sx={{ marginTop: "10px" }}>
                {motores.map((m) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={motorSeleccionado.includes(m.nombre)}
                        onChange={handleMotoresChange}
                        value={m.nombre}
                        sx={{ color: "rgba(255, 255, 255, 0.60)" }}
                      />
                    }
                    label={m.nombre.charAt(0).toUpperCase() + m.nombre.slice(1)}
                    sx={{
                      color: "rgba(255, 255, 255, 0.60)",
                      "& .MuiTypography-root": {
                        color: "rgba(255, 255, 255, 0.60)", // Asegura que el texto de la etiqueta sea blanco
                        fontWeight: "regular",
                      },
                    }}
                  />
                ))}
              </FormGroup>
              <FormHelperText
                sx={{
                  color: "rgba(255, 255, 255, 0.60)",
                  fontSize: "0.8rem",
                  width: "80%",
                  marginTop: "10px",
                }}
              >
                Selecciona uno, mas de uno o ninguno
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item lg={3}>
            <FormControl
              sx={{ m: 3, flex: "1 1 45%" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel
                component="legend"
                sx={{
                  color: "rgba(255, 255, 255, 0.60)",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  "&.Mui-focused": {
                    color: "#FFD415", // Color amarillo cuando está enfocado
                  },
                  "&.Mui-error": {
                    color: "#FFD415", // Color amarillo cuando hay un error
                  },
                }}
              >
                Transmisiones
              </FormLabel>
              <FormGroup sx={{ marginTop: "10px" }}>
                {cambio.map((c) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cambioSeleccionado.includes(c.nombre)}
                        onChange={handleCambioChange}
                        value={c.nombre}
                        sx={{ color: "rgba(255, 255, 255, 0.60)" }}
                      />
                    }
                    label={c.nombre.charAt(0).toUpperCase() + c.nombre.slice(1)}
                    sx={{
                      color: "rgba(255, 255, 255, 0.60)",
                      "& .MuiTypography-root": {
                        color: "rgba(255, 255, 255, 0.60)", // Asegura que el texto de la etiqueta sea blanco
                        fontWeight: "regular",
                      },
                    }}
                  />
                ))}
              </FormGroup>
              <FormHelperText
                sx={{
                  color: "rgba(255, 255, 255, 0.60)",
                  fontSize: "0.8rem",
                  width: "80%",
                  marginTop: "10px",
                }}
              >
                Selecciona uno, mas de uno o ninguno
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item lg={3}>
            <FormControl
              component="fieldset"
              variant="standard"
              sx={{ m: 3, flex: "1 1 45%" }}
            >
              <FormLabel
                component="legend"
                sx={{
                  color: "rgba(255, 255, 255, 0.60)",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  "&.Mui-focused": { color: "#FFD415" },
                  "&.Mui-error": { color: "#FFD415" },
                }}
              >
                Medios Ambientales
              </FormLabel>
              <FormGroup
                row
                sx={{ marginTop: "10px", justifyContent: "space-around" }}
              >
                {medios.map((medio) => (
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      border: mediosSeleccionado.includes(medio.imagen)
                        ? "3px solid #FFD415"
                        : "3px solid transparent",
                      cursor: "pointer",
                      borderRadius: "30px",
                      overflow: "hidden",
                      boxShadow: mediosSeleccionado.includes(medio.imagen)
                        ? "0px 0px 10px rgba(255, 212, 21, 0.7)"
                        : "none",
                      marginBottom: "10px",
                    }}
                    onClick={() => handleMediosImageClick(medio)}
                  >
                    <img
                      src={medio.imagen}
                      alt={medio.imagen}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                ))}
              </FormGroup>
              <FormHelperText
                sx={{
                  color: "rgba(255, 255, 255, 0.60)",
                  fontSize: "0.8rem",
                  marginTop: "10px",
                }}
              >
                Selecciona uno, más de uno o ninguno
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Card>

      <Grid container spacing={2}>
        {modelos.map((modelo, index) => (
          <Grid item xs={3} key={index}>
            <Card
              sx={{
                backgroundColor: "#181818",
                boxShadow: "none",
                maxHeight: "500px",
                maxWidth: "240px",
                overflowY: "auto",
                border:
                  parseInt(modeloSeleccionado) === parseInt(modelo.id)
                    ? "linear-gradient(to top, rgba(255, 212, 21, 0.35) 0%, rgba(255, 212, 21, 0) 100% )"
                    : "none",
              }}
              key={modelo.id}
            >
              <img
                src={modelo.imagen}
                style={{
                  borderRadius: "0",
                  objectFit: "cover",
                  marginLeft: "-0px",
                  marginBottom: "0px",
                  borderRadius: "12px",
                }}
                alt={modelo.nombre}
              />

              <MKBox p={1} mt={-1} textAlign="center" alignItems="center">
                <MKBox mt={1} mb={3} mx={0} display="flex" alignItems="center">
                  <MKBox flex={1} sx={{ textAlign: "left" }}>
                    <MKTypography
                      display="inline"
                      variant="h4"
                      style={{ color: "#ffffff" }}
                      textTransform="capitalize"
                      fontWeight="bold"
                    >
                      {modelo.nombre}
                    </MKTypography>
                  </MKBox>

                  <MKBox>
                    <AvatarGroup max={3}>
                      {modelo.medio.map((avatarUrl, index) => (
                        <Avatar
                          sx={{ width: 30, height: 30, marginTop: "10px" }}
                          key={index}
                          src={avatarUrl.trim()}
                          alt={`Avatar ${index + 1}`}
                        />
                      ))}
                    </AvatarGroup>
                  </MKBox>
                </MKBox>

                <MKBox
                  mt={-2}
                  mb={1}
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  minHeight="40px"
                >
                  {modelo.motor.map((motor, index) => (
                    <MKBadge
                      sx={{
                        marginLeft: "-5px",
                        marginRight: "5px",
                        marginBottom: "4px",
                      }}
                      size="xs"
                      badgeContent={motor}
                      variant="contained"
                      key={index}
                    />
                  ))}
                </MKBox>

                <MKTypography
                  mb={1}
                  sx={{ textAlign: "left", color: "#a8a8a8" }}
                  variant="caption"
                  component="p"
                  style={{ fontSize: "x-small" }}
                >
                  Precio entre:
                </MKTypography>

                <MKBox mt={-1} mb={3} mx={0} display="flex" alignItems="center">
                  <MKBox flex={1} sx={{ textAlign: "left" }}>
                    <h5 style={{ color: "#ffffff" }}>
                      {JSON.parse(modelo.precio)[0] === '0,00' ? 'No disp.' : JSON.parse(modelo.precio)[0] + ' €' } 
                    </h5>
                  </MKBox>

                  <MKBox display="flex" alignItems="center">
                    <MKBox
                      flex={1}
                      sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                    >
                      <h6 style={{ color: "#ffffff", fontWeight: 400 }}>
                      {" y  "}   {JSON.parse(modelo.precio)[1] === '0,00' ? 'No disp.' : JSON.parse(modelo.precio)[1] + ' €' }
                      </h6>
                    </MKBox>

                    <MKBox
                      flex={1}
                      sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                    >
                      <h6 style={{ color: "#ffffff", fontWeight: 400 }}>
                      {JSON.parse(modelo.precio)[1] === '0,00' ? '' : ' /Mes' }
                      </h6>
                    </MKBox>
                  </MKBox>
                </MKBox>

                <MKBox mt={-2} mb={2}>
                  <MKTypography
                    variant="caption"
                    component="p"
                    style={{ fontSize: "x-small", color: "#a8a8a8" }}
                  >
                    Precio y entrega sujeto a oferta final
                  </MKTypography>
                </MKBox>

                <Button
                  style={{
                    width: "100%",
                    color: "#181818",
                    backgroundColor:
                      parseInt(modeloSeleccionado) === parseInt(modelo.id)
                        ? "#a2a3a2"
                        : "#FFD415",
                  }}
                  onClick={() => {
                    setModeloSeleccionado(modelo.id);
                    setVersionSeleccionada("");
                    setColorSeleccionado("");
                    setEquipamientoSeleccionado("");
                  }}
                  size="small"
                  variant="contained"
                >
                  {parseInt(modeloSeleccionado) === parseInt(modelo.id)
                    ? "Seleccionado"
                    : "Seleccionar"}
                </Button>
              </MKBox>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>,
    <>
      <Grid container spacing={2}>
        {versiones.map((version, index) => (
          <Grid mt={2} key={index}>
            <Card
              style={{
                boxShadow: "none", //'0 8px 16px rgba(0, 0, 0, 0.5)',
                border:
                  parseInt(versionSeleccionada) === parseInt(version.id)
                    ? "1px solid rgba(255, 212, 21, 0.35)"
                    : "1px solid rgba(168, 168, 168, 0.35)",
                backgroundColor: "#181818",
                margin: "10px",
                marginTop: "10px",
                textAlign: "left",
                //cursor: 'pointer',
                overflow: "hidden",
                maxHeight: "500px",
                maxWidth: "280px",
                minWidth: "224px",
                minHeight:"150px"
                
              }}
            >
              <Box sx={{ padding: "15px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <img
                    src={version.medio}
                    style={{ width: "15%", marginRight: "15px" }}
                  />
                  <MKTypography>
                    <MKTypography
                      variant="h6"
                      style={{ color: "#FFFFFF", fontSize: "70%" }}
                      fontWeight="Regular"
                    >
                      {version.nombre.charAt(0).toUpperCase() +
                        version.nombre.slice(1)}
                    </MKTypography>
                    <MKTypography
                      style={{ fontSize: "75%" }}
                      variant="body2"
                      component="p"
                      color="text"
                    >
                      {version.motor.charAt(0).toUpperCase() +
                        version.motor.slice(1)}
                    </MKTypography>
                  </MKTypography>

                  <IconButton
                    onClick={() => {setVersionSeleccionada(version.id); setColorSeleccionado("");}}
                    sx={{
                      color:
                        parseInt(versionSeleccionada) === parseInt(version.id)
                          ? "rgba(255, 212, 21, 0.35)"
                          : " rgba(168, 168, 168, 0.35)",
                    }}
                  >
                    {parseInt(versionSeleccionada) === parseInt(version.id) ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                  </IconButton>
                </div>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>,
    <Grid container p={2} spacing={2} justifyContent="center">
      {colores.map((color, index) => (
        <Grid mt={2} p={2} key={index}>
          <Card
            style={{
              border:
                parseInt(colorSeleccionado) === parseInt(color.id)
                  ? "1px solid rgba(255, 212, 21, 0.95)"
                  : "1px solid rgba(255, 255, 255, 0.35)",
              backgroundColor: "#181818",
              boxShadow: "none",
              margin: 0,
              textAlign: "center",
              cursor: "pointer",
              maxHeight: "150px",
              maxWidth: "150px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onClick={() => setColorSeleccionado(color.id)}
          >
            <MKBox
              position="relative"
              mx={0}
              mt={0}
              overflow="hidden"
              style={{ flex: "1 1 auto" }}
            >
              <div
                style={{
                  overflow: "hidden",
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                }}
              >
                <img
                  src={color.img_color}
                  width="115%"
                  style={{
                    borderRadius: "0",
                    objectFit: "cover",
                    marginLeft: "-18px",
                  }}
                />
              </div>
            </MKBox>
            <MKBox
              justifyContent="center"
              style={{
                marginBottom: "5px",
                padding: "4px",
                textAlign: "center",
                flex: "1 1 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MKTypography
                variant="text"
                style={{ color: "#FFFFFF", fontSize: "70%" }}
                fontWeight="bold"
              >
                {color.nombre}
              </MKTypography>
            </MKBox>
          </Card>
        </Grid>
      ))}
    </Grid>,

<Grid container p={2} spacing={2} justifyContent="center">
      {equipamientos.map((equip, index) => (
                <Grid mt={2} p={2} key={index}>

        <Card
          sx={{
            backgroundColor: "#181818",
            width: "300px",
            p: 2,
            mx: { lg: 1 },
            mb: 0,
            border:
              parseInt(equipamientoSeleccionado) === parseInt(equip.id)
                ? "1px solid rgba(255, 212, 21, 0.35)"
                : "1px solid rgba(168, 168, 168, 0.35)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <h2 style={{ color: "#FFD415", margin: 0 }}>Pack {index + 1}</h2>
            <Button
              style={{
                color: "#181818",
                backgroundColor:
                  parseInt(equipamientoSeleccionado) === parseInt(equip.id)
                    ? "#a2a3a2"
                    : "#FFD415",
              }}
              onClick={() => {
                setEquipamientoSeleccionado(equip.id);
              }}
              size="small"
              variant="contained"
            >
              {parseInt(equipamientoSeleccionado) === parseInt(equip.id)
                ? "Seleccionado"
                : "Seleccionar"}
            </Button>
          </div>
          
          {equip.equipamiento.map((equipSection, secIndex) =>
            equipSection.map((section, secInnerIndex) => (
              <Accordion
                key={`${index}-${secIndex}-${secInnerIndex}`}
                sx={{
                  backgroundColor: "#181818",
                  boxShadow: "none",
                  "&:before": { display: "none" },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                  sx={{ mb: 0, ml: -2 }}
                >
                  <MKTypography
                    variant="h6"
                    sx={{ color: "#ffffff", fontWeight: 400 }}
                  >
                    {section.label
                      .toLowerCase()
                      .replace(/^\w/, (c) => c.toUpperCase())}
                  </MKTypography>
                </AccordionSummary>
                <AccordionDetails>
                  {section.items.map((item, itemIndex) => (
                    <Box key={itemIndex} sx={{ mt: 1, mb: 1 }}>
                      <CenteredBlogCardEquip
                        image={item.Imagen}
                        title={item.Item}
                        description="ddd"
                      />
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Card>
        </Grid>
      ))}
    </Grid>,

    <MKTypography>Contenido de la página 5</MKTypography>,
    <MKTypography>Contenido de la página 6</MKTypography>,
  ];

  const iconMapping = {
    Modelo: {
      icon: <DirectionsCarIcon fontSize="medium" sx={{ color: "#FFFFFF" }} />,
      subtitle: "Selecciona el modelo",
    },
    Versión: {
      icon: <BuildIcon fontSize="medium" sx={{ color: "#FFFFFF" }} />,
      subtitle: "Elige la versión",
    },
    Color: {
      icon: <PaletteIcon fontSize="medium" sx={{ color: "#FFFFFF" }} />,
      subtitle: "Escoge un color",
    },
    Equipamiento: {
      icon: <AddCircleIcon fontSize="medium" sx={{ color: "#FFFFFF" }} />,
      subtitle: "Escoge un pack",
    },
  };

  const isSmallDevice = useMediaQuery('(max-height:866px)'); // Si es menor de 600px, lo consideramos "pequeño"

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        //borderRadius: '15px',
        //mb: 5,
        padding: "0px",
        overflowY: "auto",
      }}
    >
      <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
        <Box
          sx={{
            top: "50%",
            left: "0%",
            position: "absolute",
            zIndex: 3,
            transform: "translateY(-50%)",

            display: "flex", // Asegura que los elementos hijos se alineen en fila
            flexDirection: "row", // Alinea los elementos hijos horizontalmente
          }}
        >
          {
            <Box p={2}>
              {equipamientoActual?.equipamiento.map(
                (equipSection, secIndex) => (
                  <Card
                    key={`equip-section-${secIndex}`}
                    sx={{
                      backgroundColor: "rgba(255, 212, 21, 0.0)",
                      width: "300px",
                      p: 2,
                      boxShadow: "none",
                    }}
                  >
                    {equipSection.map(
                      (section, secInnerIndex) =>
                        section.label === equipSelect &&
                        (section.items.length > 3 ? (
                          <Slider
                            {...{
                              ...settings,
                            }}
                            key={`slider-${secIndex}-${secInnerIndex}`}
                          >
                            {section.items.map((item, itemIndex) => (
                              <Box
                                key={itemIndex}
                                sx={{
                                  mt: 3,
                                  position: "relative",
                                  width: "100%",
                                  height: "150px", // Ajusta la altura según el contenido
                                  transition: "all 0.5s ease",
                                  transform:
                                    itemIndex === currentSlide
                                      ? "scale(1.05)"
                                      : "scale(1)",
                                  padding:
                                    itemIndex === currentSlide
                                      ? "0 10%"
                                      : "0 14%",
                                }}
                              >
                                <CenteredBlogCardEquip
                                  image={item.Imagen}
                                  title={item.Item}
                                  description="ddd"
                                />
                              </Box>
                            ))}
                          </Slider>
                        ) : (
                          section.items.map((item, itemIndex) => (
                            <Box
                              key={itemIndex}
                              sx={{
                                mt: 3,
                                position: "relative",
                                width: "100%",
                                height: "150px", // Ajusta la altura según el contenido
                                transition: "all 0.5s ease",
                                transform:
                                  itemIndex === currentSlide
                                    ? "scale(1.05)"
                                    : "scale(1)",
                                padding:
                                  itemIndex === currentSlide
                                    ? "0 10%"
                                    : "0 14%",
                              }}
                            >
                              <CenteredBlogCardEquip
                                image={item.Imagen}
                                title={item.Item}
                                description="ddd"
                              />
                            </Box>
                          ))
                        ))
                    )}
                  </Card>
                )
              )}
            </Box>
          }
        </Box>
        {modeloSeleccionado ? null : (
          <Box
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
              margin: "16px",
              zIndex: 3,
              display: "flex", // Asegura que los elementos hijos se alineen en fila
              flexDirection: "row", // Alinea los elementos hijos horizontalmente
              gap: "16px", // Añade espacio entre los elementos si es necesario
            }}
          >
            <MKTypography
              variant="body2"
              sx={{ color: "#ffffff", fontSize: "65%" }}
            >
              Configura un vehículo para empezar
            </MKTypography>
          </Box>
        )}
        <Box
          sx={{
            bottom: 0,
            left: "50%", // Mueve el elemento al 50% de la anchura del padre
            transform: "translateX(-50%)",
            position: "absolute",
            margin: "16px",
            zIndex: 3,
            display: "flex", // Asegura que los elementos hijos se alineen en fila
            flexDirection: "row", // Alinea los elementos hijos horizontalmente
            gap: "16px", // Añade espacio entre los elementos si es necesario
          }}
        >
          <Card
            style={{
              width: "130px",
              backgroundColor: "#181818",
              padding: "10px",
              textAlign: "center",
              display: "flex",
              flexDirection: "row", // Asegura que el contenido interno se alinee en fila
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ThreeSixtyIcon
              style={{
                color: "#FFD415",
                fontSize: "25px",
                marginRight: "10px",
              }}
            />
            <MKTypography
              variant="body2"
              sx={{ color: "#ffffff", fontSize: "65%" }}
            >
              Visor 360°
            </MKTypography>
          </Card>

          <Card
            style={{
              width: "160px",
              backgroundColor: "#FFD415",
              padding: "10px",
              textAlign: "center",
              display: "flex",
              flexDirection: "row", // Asegura que el contenido interno se alinee en fila
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={handleOpen} // Abre el modal
          >
            <SettingsIcon
              style={{
                color: "#181818",
                fontSize: "25px",
                marginRight: "10px",
              }}
            />
            <MKTypography
              variant="body2"
              sx={{ color: "#181818", fontSize: "65%" }}
            >
              Configurar vehiculo
            </MKTypography>
          </Card>
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              borderRadius: "12px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 1000,
              maxHeight: "98vh",
              backgroundColor: "#181818",
              boxShadow: 24,
              p: 4,
              pb: 8, // Deja espacio para el contenido principal
              outline: "none",
              overflow: "auto",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              {page > 0 && (
                <>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginRight: "50px",
                      }}
                    >
                      <IconButton onClick={() => setPage(0)}>
                        <ArrowBackIcon style={{ color: "#FFFFFF" }} />
                      </IconButton>
                      <MKTypography
                        style={{ color: "#FFFFFF" }}
                        variant="body2"
                      >
                        Volver
                      </MKTypography>
                    </Box>
                    {page !== pages.length - 2 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <MKTypography
                          style={{ color: "#FFFFFF" }}
                          variant="body2"
                        >
                          Continuar
                        </MKTypography>
                        <IconButton onClick={() => setPage(page + 1)}>
                          <ArrowForwardIcon style={{ color: "#FFFFFF" }} />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </>
              )}

              <Box mb={1}>
                <MKTypography
                  style={{ color: "#FFFFFF", mb: 2 }}
                  variant="h6"
                  component="h2"
                >
                  {`Configurar Vehículo`}
                </MKTypography>
              </Box>
            </Box>

            {/* Grid con botones */}
            {page === 0 ? (
              <Grid container spacing={2}>
                {["Modelo", "Versión", "Color", "Equipamiento"].map(
                  (label, index) => (
                    <Grid item xs={3} key={index}>
                      <Card
                        style={{
                          opacity: (label === 'Versión' && modeloSeleccionado) || 
                          (label === 'Modelo') || 
                          (label === 'Color' && versionSeleccionada) || 
                          (label === 'Equipamiento' && colorSeleccionado) ? 1 : 0.5,
                 
                          pointerEvents: (label === 'Versión' && modeloSeleccionado) || 
                                (label === 'Modelo') || 
                                (label === 'Color' && versionSeleccionada) || 
                                (label === 'Equipamiento' && colorSeleccionado) ? "auto" : "none",
                          padding: "20px",
                          textAlign: "center",
                          cursor: "pointer",
                          backgroundColor: "#181818",
                          boxShadow: "none",
                          border: "1px solid rgba(255, 255, 255, 0.2)",
                        }}
                        onClick={() => setPage(index + 1)} // Cambia a la página seleccionada
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          {iconMapping[label] && iconMapping[label].icon}{" "}
                          {/* Renderiza el ícono si existe */}
                          <MKTypography
                            variant="h5"
                            sx={{ color: "#FFFFFF" }}
                            mt={2}
                          >
                            {label}
                          </MKTypography>
                          {iconMapping[label] && (
                            <MKTypography
                              variant="body2"
                              color="textSecondary"
                              sx={{ fontSize: "70%", color: "#FFFFFF" }}
                              mt={0}
                            >
                              {iconMapping[label].subtitle}
                            </MKTypography>
                          )}
                        </Box>
                      </Card>
                    </Grid>
                  )
                )}
              </Grid>
            ) : (
              <Box>
                {pages[page - 1]} {/* Muestra la página seleccionada */}
              </Box>
            )}
          </Box>
        </Modal>

        <Box
          sx={{
            width: "265px",
            top: 0,
            right: 0,
            position: "absolute",
            margin: "16px",
            zIndex: 2,
            height: "calc(100% - 32px)", // Ajusta la altura restando las márgenes
          }}
        >
          <Card
            style={{
              backgroundColor: "#181818",
              marginBottom: "16px",
              padding: "10px",
              width: "265px",
            }}
          >
            <MKBox p={0} mt={0} textAlign="center" alignItems="center">
              <MKBox
                mt={0}
                mb={3}
                mx={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MKBox flex={1} sx={{ textAlign: "left" }}>
                  <MKTypography
                    display="inline"
                    variant="h4"
                    sx={{
                      color: "#ffffff",
                      textTransform: "capitalize",
                      fontWeight: "bold",
                    }}
                  >
                    {vehiculos.length > 0
                      ? vehiculos[0]?.modelo
                      : "Selecciona un modelo"}
                  </MKTypography>
                  <MKTypography
                    variant="body2"
                    sx={{ color: "#ffffff", fontSize: "75%" }}
                  >
                    {versionActual?.nombre || "Selecciona una versión"}
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={1}
                sx={{
                  borderRadius: "6px",
                  padding: "6px",
                  position: "relative",
                }}
              >
                <img
                  src={icon_delivery}
                  style={{
                    width: "24px",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
                <MKTypography
                  variant="caption"
                  component="p"
                  sx={{ fontSize: "small", color: "#ffffff" }}
                >
                  {entrega || "---"}
                </MKTypography>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#a8a8a8",
                    opacity: "30%",
                    borderRadius: "6px",
                  }}
                />
              </MKBox>
              <Divider variant="middle" />
              <MKBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={-2}
                mb={1}
              >
                <MKBox
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    src={icon_engine}
                    style={{ width: "24px", marginLeft: "10px" }}
                  />
                  <MKTypography
                    sx={{ ml: 2, color: "#a8a8a8" }}
                    variant="body2"
                  >
                    {motor || "---"}
                  </MKTypography>
                </MKBox>
                <MKBox
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={icon_gear} style={{ width: "24px" }} />
                  <MKTypography
                    sx={{ ml: 2, color: "#a8a8a8" }}
                    variant="body2"
                  >
                    {cambiop || "---"}
                  </MKTypography>
                </MKBox>
              </MKBox>
              <Divider variant="middle" />
              <MKBox
                mt={-2}
                mb={3}
                mx={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <MKBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "15px",
                  }}
                >
                  <h5
                    style={{
                      color: "#ffffff",
                      fontWeight: 400,
                      marginRight: "20px",
                    }}
                  >
                    {
  precio === undefined || isNaN(parseFloat(precio.replace(/\./g, "").replace(",", "."))) 
    ? 'No disp.' 
    : `${(
        parseFloat(precio.replace(/\./g, "").replace(",", ".")) - ((vehiculos[0]?.type === 'Turismo' ? 907.5 : 1210.00) + price)
      )
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
      €`
}

                  </h5>
                </MKBox>
                <MKBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "10px",
                  }}
                >
                  <h4 style={{ color: "#ffffff" }}>{cuota === undefined || isNaN(parseFloat(precio.replace(/\./g, "").replace(",", "."))) 
    ? 'No disp.' 
    : `
                  ${(
                parseFloat(cuota.replace(/\./g, "").replace(",", ".")) -
                ((vehiculos[0]?.type === 'Turismo' ? cuote : 36.61))
              )
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
              € `}</h4>
                  <h6
              style={{
                color: "#ffffff",
                fontWeight: 400,
                marginLeft: "5px",
                display: "flex",
                alignItems: "flex-start",
                position: "relative", // Added for position adjustment
                top: "4px", // Adjust this to move the "Mes" text down
              }}
            >
              {"/Mes"}
            </h6>
                  
                </MKBox>
              </MKBox>
              <MKBox mt={-2} mb={3}>
                <MKTypography
                  variant="caption"
                  component="p"
                  sx={{ fontSize: "x-small", color: "#a8a8a8" }}
                />
                <MKTypography
                  variant="caption"
                  component="p"
                  sx={{ fontSize: "x-small", color: "#a8a8a8" }}
                >
                  Precio y entrega recomendado sujeto a oferta final
                </MKTypography>
              </MKBox>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="outlined"
                  sx={{
                    width: "100%",
                    borderColor: "#FFD415",
                    color: "#FFD415",
                  }}
                  onClick={() => {
                    handleButtonClick();
                  }}
                >
                  BUSCAR DISPONIBILIDAD
                </Button>
              </Box>
            </MKBox>
          </Card>
          {/* Card con imagen del color */}
          {colorActual && (
            <Card
              style={{
                backgroundColor: "#181818",
                marginBottom: "16px",
                width: "265px",
                height: "45px",
                position: "relative",
              }}
            >
              <img
                src={colorActual.img_color}
                alt={`Imagen del vehículo`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                  cursor: "grab",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  //backgroundColor: "#a8a8a8",
                  opacity: "100%",
                  borderRadius: "6px",
                }}
              >
                <h6
                  style={{
                    color: "#ffffff",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    textShadow:'initial'
                  }}
                >
                  {colorActual.nombre}
                </h6>
              </Box>
            </Card>
          )}
          {equipamientoActual?.equipamiento.map((equipSection, secIndex) => (
            <Card
              sx={{
                backgroundColor: "#181818",
                width: "265px",
                p: 2,
                overflow: "auto",
                maxHeight: isSmallDevice ? '260px' : '100%', 
              }}
            >
              <h3 style={{ color: "#FFFFFF", margin: 0 }}>
                Extras seleccionados
              </h3>
              <MKTypography
                variant="caption"
                mb={2}
                sx={{ color: "#ffffff", fontWeight: 400 }}
              >
                Todos los extras estan incluidos, el check elige cuales mostrar
              </MKTypography>

              {equipSection.map((section, secInnerIndex) => (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <MKTypography
                    variant="caption"
                    sx={{ color: "#ffffff", fontWeight: 400 }}
                  >
                    {section.label
                      .toLowerCase()
                      .replace(/^\w/, (c) => c.toUpperCase())}
                  </MKTypography>
                  <IconButton
                    onClick={() => setEquipSelect(section.label)}
                    sx={{
                      color:
                        equipSelect === section.label
                          ? "rgba(255, 212, 21, 0.35)"
                          : " rgba(168, 168, 168, 0.35)",
                    }}
                  >
                    {equipSelect === section.label ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                  </IconButton>
                </Box>
              ))}
            </Card>
          ))}
        </Box>

        {carImages.length > 0 && (
          <img
            src={carImages[currentImageIndex]}
            alt={`Imagen ${currentImageIndex + 1} del vehículo`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute", //borderRadius: '10px',
              cursor: "grab",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
            onDragStart={handleDragStart}
          />
        )}
      </Box>
    </Box>
  );
};

export default ConfigFilter;

/*

*/
